import React, { PureComponent } from "react";
import "../../css/style.scss";

import { BsCheckCircleFill } from "react-icons/bs";
import { BiCircle } from "react-icons/bi";
import { GoTriangleRight, GoTriangleDown } from "react-icons/go";
import AppSubHeaderText from "./AppSubHeader";

class AppAccordian extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }
  setSelectedService = (id) => {
    this.props.setSelectedService(id);
  };
  setSelectedRepair = (id) => {
    this.props.setSelectedRepair(id);
  };
  setSelectedDescription = (id) => {
    this.props.setSelectedDescription(id);
  };

  renderInvoiceMemo = (memo) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // maxHeight: "35px",
          height: "auto",
        }}
      >
        <p
          style={{
            marginLeft: "45px",
            marginRight: "20px",
            marginVertical: "0px",
            fontFamily: "museo-sans",
            fontWeight: "300",
            color: "#727272",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            marginBottom: "20px",
            flex: 1,
          }}
        >
          <span style={{ fontWeight: "bold", marginTop: "20px" }}>NOTES:</span>{" "}
          <br></br>
          {memo}
        </p>
        <div className="yellowItemMemo">
          <p className="subPriceText" style={{ color: "#FFFFDD" }}>
            Notes
          </p>
        </div>
      </div>
    );
  };

  renderInvoiceItemDiscounts = (item, i) => {
    return (
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            marginLeft: "65px",
            marginRight: "20px",
            marginVertical: "0px",
            fontFamily: "museo-sans",
            fontWeight: "300",
            color: "#727272",
            display: "flex",
            alignItems: "center",
            marginBottom: "0px",
          }}
        >
          - Discount of {item?.Discount_Perc__c.toFixed(2)}%{" "}
          {/* {item?.Invoice_Memo__c && `- ${item?.Invoice_Memo__c}`} */}
          {/* {item &&
            `- A big long memo for testing. A big long memo for testing. A big long memo for testing. A big long memo for testing. A big long memo for testing. A big long memo for testing.`} */}
        </p>
        <div className="yellowItemPriceBar">
          <p
            className="subPriceText"
            style={{
              color:
                this.props.selectedServices.includes(item) ||
                this.props.selectedRepairs.includes(item)
                  ? "#D54100"
                  : "#727272",
            }}
          >
            - {item.Discount_Amount__c.toFixed(2)}
          </p>
        </div>
      </div>
    );
  };

  renderInvoiceItems = (item, i) => {
    // console.log(item);
    return (
      <>
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // maxHeight: "35px",
          }}
        >
          <p
            style={{
              marginLeft: "45px",
              marginRight: "20px",
              marginVertical: "0px",
              fontFamily: "museo-sans",
              fontWeight: "300",
              color: "#727272",
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            {item?.Product_Name__c}
          </p>
          <div className="yellowItemPriceBar">
            <p
              className="subPriceText"
              style={{
                color:
                  this.props.selectedServices.includes(item) ||
                  this.props.selectedRepairs.includes(item)
                    ? "#D54100"
                    : "#727272",
              }}
            >
              {/* {item.Net_Amount__c.toFixed(2)} */}
              {/* {console.log(item)} */}
              {item?.Product_Name__c === "Tax Amount"
                ? item?.Tax_Amount__c?.toFixed(2)
                : item?.Unit_Price__c?.toFixed(2)}
            </p>
          </div>
        </div>
        {item?.Discount_Perc__c && item?.Discount_Perc__c !== "0"
          ? this.renderInvoiceItemDiscounts(item, i)
          : ""}
      </>
    );
  };
  renderDiscount = (item, i) => {
    return (
      <>
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // maxHeight: "35px",
          }}
        >
          <p
            style={{
              marginLeft: "45px",
              marginRight: "20px",
              marginVertical: "0px",
              fontFamily: "museo-sans",
              fontWeight: "300",
              color: "#727272",
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Discount
          </p>
          <div className="yellowItemPriceBar">
            <p
              className="subPriceText"
              style={{
                color:
                  this.props.selectedServices.includes(item) ||
                  this.props.selectedRepairs.includes(item)
                    ? "#D54100"
                    : "#727272",
              }}
            >
              -{item.toFixed(2)}
            </p>
          </div>
        </div>
        {/* {item?.Discount_Perc__c && this.renderInvoiceItemDiscounts(item, i)} */}
      </>
    );
  };
  renderBalancePaid = (item, i) => {
    return (
      <>
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // maxHeight: "35px",
          }}
        >
          <p
            style={{
              marginLeft: "45px",
              marginRight: "20px",
              marginVertical: "0px",
              fontFamily: "museo-sans",
              fontWeight: "300",
              color: "#727272",
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Balance Paid
          </p>
          <div className="yellowItemPriceBar">
            <p
              className="subPriceText"
              style={{
                color:
                  this.props.selectedServices.includes(item) ||
                  this.props.selectedRepairs.includes(item)
                    ? "#D54100"
                    : "#727272",
              }}
            >
              -{item.toFixed(2)}
            </p>
          </div>
        </div>
        {/* {item?.Discount_Perc__c && this.renderInvoiceItemDiscounts(item, i)} */}
      </>
    );
  };
  renderCreditMemo = (item, i) => {
    return (
      <>
        <div
          key={i}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // maxHeight: "35px",
          }}
        >
          <p
            style={{
              marginLeft: "45px",
              marginRight: "20px",
              marginVertical: "0px",
              fontFamily: "museo-sans",
              fontWeight: "300",
              color: "#727272",
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            Discount
          </p>
          <div className="yellowItemPriceBar">
            <p
              className="subPriceText"
              style={{
                color:
                  this.props.selectedServices.includes(item) ||
                  this.props.selectedRepairs.includes(item)
                    ? "#D54100"
                    : "#727272",
              }}
            >
              -{item.toFixed(2)}
            </p>
          </div>
        </div>
        {/* {item?.Discount_Perc__c && this.renderInvoiceItemDiscounts(item, i)} */}
      </>
    );
  };

  renderItem = (item, i, type) => {
    return (
      <div key={item.id} style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            minHeight: "75px",
          }}
        >
          {/* Button and text */}
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 5 }}>
              <button
                style={{
                  border: "none",
                  backgroundColor: "white",
                  width: "100%",
                }}
                aria-label={`Select invoice ${item?.name}`}
                onClick={() => {
                  // console.log("item", item);
                  if (type === "Repair") {
                    this.setSelectedRepair(item.id);
                  } else {
                    this.setSelectedService(item.id);
                  }
                }}
                tabIndex={17}
              >
                {this.props.selectedServices.includes(item) ||
                this.props.selectedRepairs.includes(item) ? (
                  <BsCheckCircleFill size={35} color="#D54100" />
                ) : (
                  <BiCircle size={38} color="#B5B5B5" />
                )}
              </button>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <button
                aria-label={`Show description for invoice ${item?.name}`}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                tabIndex={18}
                onClick={() => {
                  this.setSelectedDescription(item?.id);
                }}
              >
                <p
                  className="appAccordianName"
                  style={{
                    color:
                      this.props.selectedServices.includes(item) ||
                      this.props.selectedRepairs.includes(item)
                        ? "#D54100"
                        : "#727272",
                  }}
                >
                  {item?.name}
                </p>

                {this.props.selectedDescription === item.id ? (
                  <GoTriangleDown size={28} color="#D54100" />
                ) : (
                  <GoTriangleRight size={30} color="#B5B5B5" />
                )}
              </button>

              {/* Price */}

              <div className="yellowPriceBar">
                <p
                  className="priceText"
                  style={{
                    color:
                      this.props.selectedServices.includes(item) ||
                      this.props.selectedRepairs.includes(item)
                        ? "#D54100"
                        : "#727272",
                  }}
                >
                  {item.balanceDue.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* {console.log(item)} */}
        {this.props.selectedDescription === item?.id && (
          <div>
            {item?.items
              ? item.items.map((item, i) => {
                  return this.renderInvoiceItems(item);
                })
              : ""}
            {item?.discountAmt ? this.renderDiscount(item?.discountAmt) : ""}
            {item?.balancePaid ? this.renderBalancePaid(item?.balancePaid) : ""}
            {item?.Invoice_Memo__c
              ? this.renderInvoiceMemo(item?.Invoice_Memo__c)
              : ""}
            {/* {type === "Service" &&
              // item?.Invoice_Memo__c &&
              this.renderInvoiceMemo(item.Invoice_Memo__c)} */}
          </div>
        )}
        <div
          style={{
            marginLeft: "40px",
            borderBottom:
              (type === "Service" &&
                !this.props.upcomingServices[i + 1] &&
                !this.props.upcomingRepairs?.length) ||
              (type === "Repair" && !this.props.upcomingRepairs[i + 1])
                ? "5px solid"
                : ".5px solid",
            borderBottomColor:
              (type === "Service" &&
                !this.props.upcomingServices[i + 1] &&
                !this.props.upcomingRepairs?.length) ||
              (type === "Repair" && !this.props.upcomingRepairs[i + 1])
                ? "#E9E9E9"
                : "#727272",
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <>
        {!this?.props?.upcomingServices.length &&
        !this?.props?.upcomingRepairs.length ? (
          <AppSubHeaderText
            text="No current services or repairs"
            color="#D54100"
          />
        ) : (
          <div>
            {this.props.upcomingServices.length ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginLeft: "45px" }}>
                    <AppSubHeaderText text="SERVICE" />
                  </div>
                  <div className="amountHeaderPayments">
                    <AppSubHeaderText text="AMOUNT" />
                  </div>
                </div>

                {this.props.upcomingServices.map((item, i) => {
                  return this.renderItem(item, i, "Service");
                })}
              </>
            ) : (
              ""
            )}
            {this.props.upcomingRepairs.length ? (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      marginLeft: "45px",
                      marginTop: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    <AppSubHeaderText text="REPAIRS" />
                  </div>
                  {/* <div
                    style={{ marginRight: "15px", marginTop: "20px" }}
                    className="amountHeaderPayments"
                  >
                    <AppSubHeaderText text="AMOUNT" />
                  </div> */}
                </div>

                {this.props.upcomingRepairs.map((item, i) => {
                  // console.log(item);
                  return this.renderItem(item, i, "Repair");
                })}
              </>
            ) : (
              ""
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  color: "#D54100",
                  fontFamily: "museo-sans",
                  marginRight: 25,
                }}
              >
                TOTAL
              </p>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#D54100",
                  fontFamily: "Oswald",
                  paddingLeft: "50px",
                  paddingRight: "15px",
                }}
                className="yellowPriceBar"
              >
                ${this.props.total}
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AppAccordian;
