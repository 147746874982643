import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppHeaderText from "../CustomComponents/AppHeaderText";
import AppNavbar from "../CustomComponents/AppNavbarWave";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";
import AppButton from "../CustomComponents/AppButton";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";
import "../../css/checkout.css";
class AddNewPaymentMethod extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      firstName: "",
      lastName: "",
      paymentType: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      zipCode: "",
      bankName: "",
      bankRoutingNumber: "",
      bankRoutingNumberFormatted: undefined,
      bankAccountNumber: "",
      bankAccountNumberFormatted: "",
      cardBrand: "",
      cardNumber: "",
      cardNumberFormatted: undefined,
      cardExpirationMonth: "",
      cardExpirationMonthFormatted: undefined,
      cardExpirationYear: "",
      cardExpirationYearFormatted: undefined,
      cardSecurity: "",
      cardSecurityFormatted: undefined,
      email: "",
      paymentGateway: "",
      gatewayCustomer: "",
      spinnerText: "",
      showResponse: false,
      showError: false,
      showConfirmation: false,
      showVerificationFail: false,
      showGatewayModal: false,
      gatewayErrorMessage: "",
      autopaySelected: false,
      currentAutopays: [],
      currentDefaults: [],
      customerSyncId: "",
    };
  }
  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");

    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      try {
        this.setState({ spinner: true });
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let data = getContact.data.records;
        // console.log("data", data[0]?.pymt__Synchronization_Id__c);
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let accountData = getAccount.data.records?.[0];
        // console.log(accountData?.Processor_Connection_Linvio__c);

        let getAutopays = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/currentAutopays`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let getDefaultPayments = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/currentDefaultPayments`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log("defaults", getDefaultPayments);
        // console.log("acctdata", accountData);
        this.setState({
          synchronization_id: data[0]?.pymt__Synchronization_Id__c,
          addressOne: data[0]?.MailingAddress?.street,
          city: data[0]?.MailingAddress?.city,
          state: data[0]?.MailingAddress.stateCode,
          zipCode: data[0]?.MailingAddress.postalCode,
          email: data[0]?.Email,
          phone: data[0]?.Formatted_Phone__c,
          firstName: data[0]?.FirstName,
          lastName: data[0]?.LastName,
          accountData: accountData,
          currentAutopays: getAutopays?.data?.records,
          currentDefaults: getDefaultPayments?.data?.records,
        });
        this.initCreateMethod(
          data[0]?.pymt__Synchronization_Id__c,
          accountData?.Processor_Connection_Linvio__c,
          data
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  checkPaymentStatus = async (id) => {
    let statusObj = {
      id: id,
    };
    let finalStatusJsonObj = JSON.stringify(statusObj);
    let checkACHStatus = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/checkPaymentStatus`,
      {
        params: {
          json: finalStatusJsonObj,
          token: localStorage.getItem("token"),
          baseDomain: window.location.href,
        },
      }
    );
    return `${checkACHStatus?.data?.records[0]?.pymt__Status__c}`;
  };
  handlePaymentStatusUpdate = async (paymentStatus, id) => {
    // IF PAYMENT STATUS IS GOOD
    if (
      paymentStatus === "Verified" ||
      paymentStatus === "Validated" ||
      paymentStatus === "Pending" ||
      paymentStatus === "Valid"
    ) {
      this.setState({
        spinner: false,
        showConfirmation: true,
        showResponse: true,
      });
      // IF PAYMENT STATUS IS BAD
    } else if (
      paymentStatus === "Invalid" ||
      paymentStatus === "Verification Failed" ||
      paymentStatus === "Errored" ||
      paymentStatus === "" ||
      paymentStatus === null ||
      paymentStatus === "null"
    ) {
      this.setState({
        spinner: false,
        showError: true,
        showResponse: true,
        showVerificationFail: true,
      });
    }
  };

  createLinvioPayMethods = async (id, paymentProcessor, data) => {
    // console.log(
    //   id,
    //   data?.[0]?.pymt__Synchronization_Id__c,
    //   paymentProcessor,
    //   this.state.accountData?.Processor_Connection_Linvio__c
    // );
    const items = [{ id, paymentProcessor, data }];
    // console.log("items", items);
    let stripeCustomerResponse;
    try {
      stripeCustomerResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/new/services/get-stripe-customer`,
        {
          params: {
            body: JSON.stringify({ items }),
            baseDomain: window.location.href,
          },
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (err) {
      console.log("get curst response");
    }

    // console.log(
    //   "Stripe customer response",
    //   stripeCustomerResponse?.data?.methods?.stripe_customer_id
    // );

    // let paymentMethodResponse = await axios.get(
    //   `${GLOBAL_DOMAIN}/api/new/services/get-payment-methods`,
    //   {
    //     params: {
    //       body: JSON.stringify({ items }),
    //       baseDomain: window.location.href,
    //     },
    //     headers: { "Content-Type": "application/json" },
    //   }
    // );

    // console.log("Stripe method response", paymentMethodResponse);

    let stripeCustId;

    if (!stripeCustomerResponse?.data?.methods?.stripe_customer_id) {
      let stripeCustResponse = await axios.get(
        `${GLOBAL_DOMAIN}/api/new/services/create-stripe-customer`,
        {
          params: {
            body: JSON.stringify({ items }),
            baseDomain: window.location.href,
          },
          headers: { "Content-Type": "application/json" },
        }
      );
      // console.log("stripecustresp", stripeCustResponse);
      if (!stripeCustResponse?.data?.customer) {
        let string = "Error getting gateway customer";
        this.setState({
          spinner: false,
          showGatewayModal: true,
          gatewayErrorMessage: string,
        });
      } else {
        stripeCustId = stripeCustResponse?.data?.customer?.stripe_customer_id;
      }
    } else {
      stripeCustId = stripeCustomerResponse?.data?.methods?.stripe_customer_id;
    }

    const itemsWithStripe = [
      {
        id,
        paymentProcessor,
        data,
        stripeCust: stripeCustId,
      },
    ];
    // console.log("check", itemsWithStripe);

    let response = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/create-payment-method`,
      {
        params: {
          body: JSON.stringify({ items: itemsWithStripe }),
          baseDomain: window.location.href,
        },
        headers: { "Content-Type": "application/json" },
      }
    );
    // console.log("create payment method response", response);
    if (
      response?.data?.public_key &&
      response?.data?.methods?.synchronization_id &&
      id &&
      paymentProcessor
    ) {
      const configuration = {
        linvioPayPublicKey: response?.data?.public_key,
        synchronization_id: id,
        paymentMethodId: response?.data?.methods?.synchronization_id,
        mode: "prod",
        // mode: "test",
      };
      // console.log(configuration);
      const startUterm = () => {
        if (!window.uTerm) {
          setTimeout(startUterm, 500);
          return;
        }
        this.setState({ spinner: false });
        window.uTerm("#terminal", configuration);
        window.onload = function () {
          const elements = document.querySelectorAll(
            ".p-BankView--grid .p-BankView--text"
          );
          elements.forEach((element) => {
            element.style.display = "none";
          });
        };
      };
      startUterm();
      return await response.data;
    } else {
      let string = "";
      if (!response?.data?.public_key) {
        string = string + "Error getting public key. ";
      }
      if (!id) {
        string = string + "Error ugetting user ID. ";
      }
      if (!paymentProcessor) {
        string = string + "Error ugetting user payment processor. ";
      }
      if (!response?.data?.methods?.synchronization_id) {
        string = string + "Error getting payment sync ID. ";
      }
      // console.log("string", string);
      this.setState({
        spinner: false,
        showGatewayModal: true,
        gatewayErrorMessage: string,
      });
    }
  };

  initCreateMethod = async (id, paymentProcessor, data) => {
    await this.createLinvioPayMethods(id, paymentProcessor, data);
  };

  render() {
    return (
      <>
        <AppNavbar />
        {this.state.spinner ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "-65px",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p
                style={{
                  fontFamily: "museo-sans",
                  marginTop: 10,
                  textAlign: "center",
                  marginLeft: "5%",
                  marginRight: "5%",
                  maxWidth: "90%",
                }}
              >
                {this.state.spinnerText}
              </p>
            </div>
          </div>
        ) : (
          <>
            {this.state.showResponse ? (
              <div className="contentContainer">
                <div style={{ marginTop: "35px", marginBottom: "30px" }}>
                  <AppHeaderText
                    text={this.state.showConfirmation ? "Success" : "Error"}
                  />
                </div>
                <p className="addPaymentMethodSubHeader">
                  {this.state.showConfirmation
                    ? "Your payment method was added successfully"
                    : "There was an error processing your payment method"}
                </p>
                {this.state.paymentType === "Bank Account" &&
                !this.state.showError ? (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "40px",
                    }}
                  >
                    A microdeposit will be placed on your ACH account before
                    this payment method can be used. This microdeposit may take
                    2-3 business days to appear, and you will receive an email
                    from us alerting you when completed. Please log into your
                    bank account online and confirm you see the deposit, then
                    call your local office and we can finish adding this as a
                    verified payment option. Note that you cannot use a new
                    account for payment until it has been fully verfied by
                    completing the entire process.
                  </p>
                ) : (
                  ""
                )}
                {this.state.showVerificationFail ? (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "40px",
                    }}
                  >
                    Your payment was recieved, but failed verification. Please
                    ensure all fields are filled out correctly, or contact our
                    customer services.
                  </p>
                ) : (
                  ""
                )}
                {this.state.showError ? (
                  <div className="addPaymentMethodConfirmationButtonsContainer">
                    <AppButton
                      tabIndex={17}
                      text="Try Again"
                      onClick={() =>
                        this.setState({
                          showResponse: false,
                          showVerificationFail: false,
                        })
                      }
                    />
                    <Link
                      to={"/Contact"}
                      style={{
                        color: "#D54100",
                        textDecoration: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      tabIndex={38}
                      aria-label="Navigate to contact screen"
                    >
                      <div
                        className="appButton"
                        style={{
                          marginTop: "10px",
                          color: "#1A4EA2",
                          backgroundColor: "#FFE160",
                          border: "none",
                          borderRadius: 25,
                          width: "fit-content",
                          fontSize: "20px",
                          fontWeight: "700",
                          fontFamily: "museo-sans",
                          letterSpacing: "2.5px",
                          marginBottom: "0px",
                          paddingRight: "40px",
                          paddingLeft: "40px",
                          textDecoration: "none",
                        }}
                      >
                        <p
                          style={{
                            marginBottom: "0px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Contact Customer Service
                        </p>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <Link
                    to={"/Payments"}
                    className="returnHomeLink"
                    style={{
                      color: "#D54100",
                    }}
                    tabIndex={17}
                    aria-label="Navigate to payments screen"
                  >
                    NAVIGATE TO PAYMENTS SCREEN
                  </Link>
                )}
              </div>
            ) : (
              <div className="contentContainer">
                <div style={{ marginTop: "35px", marginBottom: "30px" }}>
                  <AppHeaderText text="Add New Payment Method" />
                </div>
                <div id="terminal"></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <PrivacyPolicy color="black" tabindex={34} />
                  <p
                    style={{
                      color: "black",
                      fontWeight: "500",
                      marginRight: "10px",
                      fontSize: "13px",
                    }}
                  >
                    |
                  </p>
                  <TermsAndPrivacy color="black" tabindex={35} />
                </div>
              </div>
            )}
            <Modal
              show={this.state.showGatewayModal}
              onHide={() => this.setState({ showGatewayModal: false })}
              aria-labelledby="Add payment gateway info error modal"
              centered
              contentClassName="custom-modal-dashboard"
              style={{ borderRadius: "25px" }}
            >
              <Modal.Body>
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "25px",
                        fontFamily: "Oswald",
                        color: "#d44203",
                        fontWeight: "300",
                        lineHeight: "30px",
                        marginBottom: "50px",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {/* {this.state.modalMessage} */}
                      Error getting account info
                    </p>
                    <p
                      style={{
                        fontSize: "22px",
                        fontFamily: "Oswald",
                        color: "#d44203",
                        fontWeight: "300",
                        lineHeight: "30px",
                        marginBottom: "50px",
                        textAlign: "center",
                      }}
                    >
                      {this.state.gatewayErrorMessage}
                    </p>
                    <div>
                      <Link
                        to={"/Contact"}
                        style={{
                          color: "#D54100",
                          textDecoration: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        tabIndex={38}
                        aria-label="Navigate to contact screen"
                      >
                        <div
                          className="appButton"
                          style={{
                            marginTop: "10px",
                            color: "#1A4EA2",
                            backgroundColor: "#FFE160",
                            border: "none",
                            borderRadius: 25,
                            width: "fit-content",
                            fontSize: "20px",
                            fontWeight: "700",
                            fontFamily: "museo-sans",
                            letterSpacing: "2.5px",
                            marginBottom: "20px",
                            paddingRight: "40px",
                            paddingLeft: "40px",
                            textDecoration: "none",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "0px",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            Contact Customer Service
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(AddNewPaymentMethod);
