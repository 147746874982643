import React, { PureComponent } from "react";

import { connect } from "react-redux";
import AppNavbar from "../CustomComponents/AppNavbarWave";
import AppButton from "../CustomComponents/AppButton";
import {
  GLOBAL_DOMAIN,
  GLOBAL_SF_DOMAIN,
  GLOBAL_WEB_DOMAIN,
} from "../../actions/types";
import axios from "axios";
import { RiCheckboxLine, RiCheckboxBlankLine } from "react-icons/ri";
import { RxCheckCircled, RxCrossCircled, RxPlusCircled } from "react-icons/rx";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import TermsAndPrivacy from "../CustomComponents/TermsAndPrivacy";

import writeIcon from "../../assets/svg-icos/ico-edit_write.svg";
import writeWhiteIcon from "../../assets/svg-icos/ico-edit_write_white.svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import LoadErrorModal from "../CustomComponents/LoadErrorModal";
import Spinner from "react-bootstrap/Spinner";
import PrivacyPolicy from "../CustomComponents/PrivacyPolicy";

class AccountDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contactData: {},
      accountData: {},
      userData: {},
      showLoadErrorModal: false,
      smsOptIn: false,
      paymentMethods: [],
      showContactMethodFailModal: false,
      cellPhone: "",
      phone: "",
      scrollToOnLoad: "",
      spinner: false,
      showPaymentUpdateModal: false,
      showPaymentUpdateSpinner: false,
      showPaymentUpdateSuccess: false,
      showPaymentUpdateError: false,
      paymentUpdateModalType: "",
      preferredPaymentMethod: {},
      selectedPaymentMethod: {},
      showNotificationsSpinner: false,
      showChangeSMSResponse: false,
      smsChangeResponse: false,
      currentDefaults: [],
      currentSelectedDefault: {},
      linvioPaymentMethods: [],
    };
  }

  componentDidMount = async () => {
    let accountID = localStorage.getItem("accountId");
    let contactID = localStorage.getItem("contactId");
    if (!accountID && !contactID) {
      window.location.href = `${GLOBAL_SF_DOMAIN}/services/oauth2/authorize?client_id=3MVG9hq7jmfCuKfcfEvXMNxD433CfsCPKAYx8ArK6TiGKw1aiwZ6qkalCe7mrAraIjbIxajp2kecEnIMR1U40&redirect_uri=https://${GLOBAL_WEB_DOMAIN}/login&scope=refresh_token%20api%20web&response_type=code&prompt=login`;
    } else {
      const value = this.props.match.params.value;
      if (value) {
        this.handleScrollToOnLoad(value);
      }
      try {
        this.setState({ spinner: true });
        let getContact = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/contact`,
          {
            params: {
              Id: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log(getContact);
        this.setState({});
        let getAccount = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/account`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log("Acct", getAccount);
        let getUser = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/services/user`,
          {
            params: {
              Id: localStorage.getItem("accountId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        let data = getContact.data.records;
        // console.log("contact", data);
        let accountData = getAccount.data.records;
        // console.log("account", accountData);
        let userData = getUser.data.records;
        // GET PAYMENT INFO
        let getPayment = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/options`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log("getPayment", getPayment);
        // let response = await axios.get(
        //   `${GLOBAL_DOMAIN}/api/new/services/get-payment-methods`,
        //   {
        //     params: {
        //       // body: JSON.stringify({ items }),
        //       baseDomain: window.location.href,
        //     },
        //     headers: { "Content-Type": "application/json" },
        //   }
        // );
        // console.log(response?.data?.methods?.results);

        let filteredLinvioPaymentData = [];
        if (getPayment?.data?.records?.length) {
          for (let i = 0; i < getPayment?.data?.records?.length; i++) {
            if (getPayment?.data?.records?.[i]?.pymt__Default__c) {
              this.setState({
                currentSelectedDefault: getPayment?.data?.records?.[i],
              });
            }
            if (
              getPayment?.data?.records?.[i]?.pymt__Status__c ===
                "In Process" ||
              getPayment?.data?.records?.[i]?.pymt__Status__c === "succeeded" ||
              getPayment?.data?.records?.[i]?.pymt__Status__c === "Completed"
            ) {
              filteredLinvioPaymentData.push(getPayment?.data?.records?.[i]);
            }
          }
        }
        // console.log(filteredLinvioPaymentData);
        this.setState({
          linvioPaymentMethods: filteredLinvioPaymentData,
        });
        let paymentData = getPayment.data.records;
        let validStatuses = "Verified, Validated, Valid, Pending";
        let filteredPaymentData = [];
        if (paymentData?.length) {
          filteredPaymentData = paymentData.filter((item) =>
            validStatuses.includes(item.pymt__Status__c)
          );
        }
        let phoneNum = data[0]?.SMS_Mobile_Number_Marketing_Only__c;
        if (data[0]?.SMS_Mobile_Number_Marketing_Only__c) {
          if (phoneNum.charAt(0) === "1") {
            // Remove the first character ('1') from phoneNum
            phoneNum = phoneNum.slice(1);
          }
        }

        let getInvoices = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/unpaidInvoices`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );

        let invoices = getInvoices.data.records;
        // console.log("unpaid", invoices);
        let balance = 0;

        if (invoices?.length) {
          for (let i = 0; i < invoices?.length; i++) {
            // CHECK FOR PENDING PAYMENTS MADE ON INVOICE
            if (invoices[i]?.Invoice_Payments__r?.records?.length) {
              let paymentRecords = invoices[i]?.Invoice_Payments__r?.records;
              for (let j = 0; j < paymentRecords.length; j++) {
                if (paymentRecords[j]?.pymt__Status__c === "Pending") {
                  balance = balance - paymentRecords[j]?.pymt__Amount__c;
                }
              }
            }
            balance = balance + invoices[i]?.Balance_Due__c;
          }
        }
        let getDefaultPayments = await axios.get(
          `${GLOBAL_DOMAIN}/api/new/payment/currentDefaultPayments`,
          {
            params: {
              contactId: localStorage.getItem("contactId"),
              token: localStorage.getItem("token"),
              baseDomain: window.location.href,
            },
          }
        );
        // console.log("defaults", getDefaultPayments);
        if (balance > 0) {
          balance = balance.toFixed(2);
        }
        if (accountData && data) {
          this.setState({
            accountData: accountData[0],
            contactData: data,
            userData: userData[0],
            phone: data[0]?.Phone,
            cellPhone: phoneNum,
            paymentMethods: filteredPaymentData,
            smsOptIn: data[0]?.SMS_Opt_In__c,
            outstandingBalance: Number(balance),
            currentDefaults: getDefaultPayments?.data?.records,
          });
          this.setState({ spinner: false });
        } else {
          this.setState({ showLoadErrorModal: true });
        }
        if (paymentData?.length) {
          for (let i = 0; i < paymentData?.length; i++) {
            if (paymentData[i]?.pymt__Default__c) {
              this.setState({ preferredPaymentMethod: paymentData[i] });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleScrollToOnLoad = (section) => {
    let elementDiv;
    let element;
    switch (section) {
      case "Notifications":
        elementDiv = document.getElementById("NotificationPrefDiv");
        element = document.getElementById("NotificationPrefTabToEmail");
        break;
      case "AutoPay":
        elementDiv = document.getElementById("AutoPayDiv");
        break;
      case "AddSMS":
        elementDiv = document.getElementById("NotificationPrefDiv");
        element = document.getElementById("NotificationPrefTabToSMS");
        break;
      default:
        return;
    }
    this.setState({ scrollToOnLoad: section });
    window.scrollTo({ top: elementDiv.offsetTop, behavior: "smooth" });
    if (element) {
      element.focus();
    }
    setTimeout(() => {
      this.setState({ scrollToOnLoad: "" });
    }, 3000);
  };

  // handleAutopayChange = () => {
  //   console.log("HIT !");
  //   let newSwitchState = !this.state.preferredPaymentMethod?.Auto_Pay__c;
  //   this.setState({ autopaySwitchChecked: newSwitchState });
  //   this.submitAutoPayChange(
  //     newSwitchState,
  //     this.state.preferredPaymentMethod.Id
  //   );
  // };

  submitAutoPayChange = async (newState, id) => {
    this.setState({ showPaymentUpdateSpinner: true });

    let obj = {
      id,
      autoPay: newState,
    };
    let finalJsonObj = JSON.stringify(obj);
    // console.log(obj);
    let changeAutoPay = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/changeAutoPay`,
      {
        params: {
          json: finalJsonObj,
          token: localStorage.getItem("token"),
          baseDomain: window.location.href,
        },
      }
    );
    if (changeAutoPay?.data?.success) {
      this.setState({
        showPaymentUpdateSpinner: false,
        // showPaymentUpdateModal: false,
        showPaymentUpdateSuccess: true,
      });
    } else {
      this.setState({
        showPaymentUpdateSpinner: false,
        showPaymentUpdateError: true,
        // showPaymentUpdateModal: false,
      });
    }
  };

  submitPreferredMethodChange = async () => {
    // console.log("this", this.state.currentSelectedDefault?.Auto_Pay__c);
    this.setState({ showPaymentUpdateSpinner: true });
    try {
      if (this.state?.currentDefaults?.length) {
        for (let i = 0; i < this.state.currentDefaults?.length; i++) {
          // console.log(this.state.currentDefaults[i].Id);
          let obj = {
            id: this.state.currentDefaults[i].Id,
            pymt__Default__c: false,
          };
          let finalJsonObj = JSON.stringify(obj);
          let changeDefault = await axios.get(
            `${GLOBAL_DOMAIN}/api/new/services/updateDefaultPayments`,
            {
              params: {
                json: finalJsonObj,
                token: localStorage.getItem("token"),
                baseDomain: window.location.href,
              },
            }
          );
          // console.log("change", changeDefault);
          let autopayObj = {
            id: this.state.currentDefaults[i].Id,
            autoPay: false,
          };
          let finalAutopayJsonObj = JSON.stringify(autopayObj);
          let changeAutoPay = await axios.get(
            `${GLOBAL_DOMAIN}/api/new/services/changeAutoPay`,
            {
              params: {
                json: finalAutopayJsonObj,
                token: localStorage.getItem("token"),
                baseDomain: window.location.href,
              },
            }
          );
          console.log(changeAutoPay?.success);
          console.log(changeDefault?.success);
        }
      }
      // for (let y = 0; y < this.state.linvioPaymentMethods?.length; y++) {
      //   if (this.state.linvioPaymentMethods[y]?.Auto_Pay__c) {
      //     console.log(
      //       "HIT autopay",
      //       this.state.linvioPaymentMethods[y],
      //       this.state.selectedPaymentMethod.Id
      //     );
      //   }
      // }
      let obj = {
        id: this.state.selectedPaymentMethod.Id,
        pymt__Default__c: true,
        accountId: localStorage.getItem("accountId"),
      };
      // console.log(obj);
      let finalJsonObj = JSON.stringify(obj);
      let changePreferredPaymentMethod = await axios.get(
        `${GLOBAL_DOMAIN}/api/new/services/changePreferredPayment`,
        {
          params: {
            json: finalJsonObj,
            token: localStorage.getItem("token"),
            baseDomain: window.location.href,
          },
        }
      );

      // if (this.state.currentSelectedDefault?.Auto_Pay__c) {
      //   let autopayObj = {
      //     id: this.state.selectedPaymentMethod.Id,
      //     autoPay: true,
      //   };
      //   let finalAutopayJsonObj = JSON.stringify(autopayObj);
      //   let changeAutoPayFinal = await axios.get(
      //     `${GLOBAL_DOMAIN}/api/new/services/changeAutoPay`,
      //     {
      //       params: {
      //         json: finalAutopayJsonObj,
      //         token: localStorage.getItem("token"),
      //         baseDomain: window.location.href,
      //       },
      //     }
      //   );
      //   console.log(changeAutoPayFinal?.success);
      // }

      if (changePreferredPaymentMethod?.data?.success) {
        this.setState({
          showPaymentUpdateSpinner: false,
          // showPaymentUpdateModal: false,
          showPaymentUpdateSuccess: true,
        });
      } else {
        this.setState({
          showPaymentUpdateSpinner: false,
          showPaymentUpdateError: true,
          // showPaymentUpdateModal: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleAutopayChange = () => {
    // console.log("HIT 2");
    // console.log(this.state.preferredPaymentMethod.Auto_Pay__c);
    let newSwitchState = !this.state.preferredPaymentMethod?.Auto_Pay__c;
    // // console.log(`autopay switch hit`, newSwitchState, item);
    this.setState({ autopaySwitchChecked: newSwitchState });
    this.submitAutoPayChange(
      newSwitchState,
      this.state.preferredPaymentMethod.Id
    );
  };

  handleSMSChangeResponse = (success) => {
    // console.log(success);
    if (success) {
      this.setState({ smsChangeResponse: true });
    } else {
      this.setState({ smsChangeResponse: false });
    }
    this.setState({
      showChangeSMSResponse: true,
      showNotificationsSpinner: false,
    });
    setTimeout(() => {
      this.setState({
        showChangeSMSResponse: false,
        smsChangeResponse: false,
      });
    }, 2500);
  };
  submitSMSChange = async () => {
    this.setState({ showNotificationsSpinner: true });
    let obj = {
      contactId: localStorage.getItem("contactId"),
      number: `1${this.state.cellPhone}`,
    };
    let finalJsonObj = JSON.stringify(obj);
    let changeSMS = await axios.get(
      `${GLOBAL_DOMAIN}/api/new/services/updateSMS`,
      {
        params: {
          json: finalJsonObj,
          token: localStorage.getItem("token"),
          baseDomain: window.location.href,
        },
      }
    );
    // console.log(changeSMS);
    if (changeSMS?.data?.success) {
      this.handleSMSChangeResponse(true);
    } else {
      this.handleSMSChangeResponse(false);
    }
  };

  handleCloseContactMethodModal = () => {
    this.setState({ showContactMethodFailModal: false });
  };
  handleClosePaymentUpdateModal = () => {
    if (this.state.showPaymentUpdateSuccess) {
      window.location.href = "/AccountDetails/AutoPay";
    } else {
      this.setState({ showPaymentUpdateModal: false });
    }
  };

  // handlePreferredContactMethodUpdate = (type) => {
  //   // check if type is being removed or added
  //   this.setState({ showNotificationsSpinner: true });
  //   if (
  //     this.state.preferredContactMethods.includes(type) &&
  //     this.state.preferredContactMethods.length === 1
  //   ) {
  //     console.log("One method must be selected");
  //     this.setState({ showContactMethodFailModal: true });
  //   } else {
  //     // CHECK IF TYPE SELECTED IS ALREADY PREFERRED
  //     if (this.state.preferredContactMethods.includes(type)) {
  //       // IF ALREADY SELECTED GO THROUGH OPTIONS, KEEP OPTIONS THAT DONT MATCH
  //       this.setState({
  //         preferredContactMethods: this.state.preferredContactMethods.filter(
  //           function (selection) {
  //             return selection !== type;
  //           }
  //         ),
  //       });
  //       // SEND API CALL TO REMOVE PREFERRED ITEM
  //       // SAID API CALL

  //       // IF ITEM IS NOT ALREADY SELECTED, ADD IT
  //     } else {
  //       let newArray = [...this.state.preferredContactMethods, type];
  //       this.setState({ preferredContactMethods: newArray });
  //       // API CALL TO ADD PREFERRED ITEM
  //     }
  //     console.log(`method of contact change clicked ${type}`);
  //     console.log(`types include ${this.state.preferredContactMethods}`);
  //   }
  //   this.setState({ showNotificationsSpinner: false });
  // };

  handleSMSOptIn = async () => {
    this.setState({ showNotificationsSpinner: true });
    let newState = !this.state.smsOptIn;
    // console.log(newState);
    if (newState === false) {
      let token = localStorage.getItem("token");
      let contactId = localStorage.getItem("contactId");
      let submitEditSMSOptIn = await axios.get(
        `${GLOBAL_DOMAIN}/api/new/services/editSMSOptIn`,
        {
          params: {
            newState,
            token,
            contactId,
            baseDomain: window.location.href,
          },
        }
      );
      // console.log(submitEditSMSOptIn);
      if (submitEditSMSOptIn?.data?.success) {
        this.handleSMSChangeResponse(true);
        this.setState({ smsOptIn: newState });
      } else {
        this.handleSMSChangeResponse(false);
      }
    } else {
      this.setState({ smsOptIn: newState, showNotificationsSpinner: false });
    }
  };

  renderCards = () => {
    if (!this.state.spinner) {
      return this.state.linvioPaymentMethods.map((item) => {
        // console.log(item);
        let errorsExist = false;
        let errorMessage = "";
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const expMonth = parseInt(item?.pymt__Expiration_Month__c, 10);
        const expYear = parseInt(item?.pymt__Expiration_Year__c, 10);

        if (
          !item?.pymt__Billing_City__c ||
          !item?.pymt__Billing_State__c ||
          !item?.pymt__Billing_Street__c
        ) {
          errorsExist = true;
          errorMessage = `MISSING BILLING INFO, CONTACT SUPPORT`;
        }
        if (
          expYear < currentYear ||
          (expYear === currentYear && expMonth < currentMonth)
        ) {
          errorsExist = true;
          errorMessage = errorMessage?.length
            ? `${errorMessage} - EXPIRED`
            : `EXPIRED`;
        }
        return (
          <Button
            tabIndex={26}
            key={item?.Id}
            disabled={errorsExist}
            onClick={() => {
              // console.log(item);
              if (item?.pymt__Default__c) {
                return;
              } else if (item?.pymt__Status__c === "Pending") {
                return;
              } else {
                this.setState({
                  selectedPaymentMethod: item,
                  paymentUpdateModalType: "Preferred",
                  showPaymentUpdateModal: true,
                });
              }
            }}
            style={{
              alignItems: "center",
              boxShadow: item?.pymt__Default__c && "0px 0px 20px #0000001A",
              border: "1px solid #E9E9E9",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              backgroundColor:
                item?.pymt__Status__c === "Pending" ? "#E9E9E9" : "transparent",
              padding: "0px",
              marginBottom: "25px",
            }}
            className="blackUnderlineHover cardHover"
            aria-label={`Set card ending in ${item?.pymt__Last_4_Digits__c} as default payment method`}
          >
            {item?.pymt__Default__c && (
              <div
                style={{
                  backgroundColor: "#1A4EA2",
                  height: "150px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              >
                <p
                  style={{
                    fontFamily: "museo-sans",
                    fontWeight: "700",
                    fontSize: "11px",
                    margin: "5px",
                    transform: "rotate(-90deg)",
                    transformOrigin: "center",
                    translate: "0px 80px",
                    width: "20px",
                  }}
                >
                  PREFERRED
                </p>
              </div>
            )}
            <div style={{ width: "100%" }}>
              <div
                style={{
                  color: "black",
                  margin: item?.pymt__Default__c
                    ? "20px 0px 20px 15px"
                    : "20px 0px 20px 45px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "25px",
                  }}
                >
                  <p
                    style={{
                      marginBottom: "0px",
                      fontFamily: "museo-sans",
                      fontWeight: "700",
                      color: errorsExist ? "red" : "black",
                    }}
                  >
                    {item?.pymt__Payment_Type__c === "ACH"
                      ? item?.pymt__Bank_Account_Name__c ||
                        item?.pymt__Bank_Name__c
                      : item?.pymt__Card_Type__c}{" "}
                    {errorsExist && `- (${errorMessage})`}
                  </p>
                  {/* <img
                  src={writeIcon}
                  alt="React Logo"
                  style={{
                    height: "25px",
                    // position: "absolute",
                  }}
                /> */}
                </div>
                <p
                  style={{
                    marginBottom: "0px",
                    fontFamily: "museo-sans",
                  }}
                >
                  {item?.pymt__Payment_Type__c === "ACH"
                    ? "ACH"
                    : "Credit card"}{" "}
                  ending in **** {item?.pymt__Last_4_Digits__c}{" "}
                  {item?.status === "In Process" && "- PENDING"}
                </p>
              </div>
              {item?.pymt__Default__c && (
                <div
                  className="redUnderlineHover"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingRight: 20,
                  }}
                >
                  <p
                    style={{
                      color: item?.Auto_Pay__c ? "#D54100" : "#727272",
                      marginBottom: "0px",
                      fontFamily: "museo-sans",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginRight: 20,
                    }}
                  >
                    Autopay is currently {item?.Auto_Pay__c ? "ON" : "OFF"}
                  </p>
                  {!item?.Auto_Pay__c && (
                    <div>
                      <Switch
                        height={45}
                        width={100}
                        onChange={() =>
                          this.setState({
                            paymentUpdateModalType: "Auto Pay",
                            showPaymentUpdateModal: true,
                          })
                        }
                        // disabled={item?.Auto_Pay__c}
                        checked={item?.Auto_Pay__c}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#EEEEEE"
                        onHandleColor="#1A4EA2"
                        offColor="#EEEEEE"
                        offHandleColor="#727272"
                        // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        tabIndex={27}
                        aria-label={`Turn on auto pay for card ending in ${item?.pymt__Last_4_Digits__c}`}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Button>
        );
      });
    } else {
      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <AppNavbar />
        <div style={{ backgroundColor: "white" }}>
          <div className="contentContainer">
            <h1
              className="myAccountHeader"
              style={{
                fontSize: "42px",
                color: "#1A4EA2",
                fontFamily: "Oswald",
                fontWeight: "300",
              }}
            >
              My account
            </h1>
            {/* <Link
              className="redUnderlineHover"
              to={"/EditAccountDetails"}
              style={{
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
                marginTop: "-10px",
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: "20px",
              }}
              tabIndex={19}
              aria-label="Navigate to change password"
            >
              <p
                style={{
                  textAlign: "right",
                  color: "#D54100",
                  fontSize: "18px",
                  fontFamily: "museo-sans",
                  fontWeight: "700",
                  marginRight: "20px",
                }}
              >
                Change password{" "}
              </p>
              <img
                src={writeIcon}
                alt="Change password icon"
                style={{ height: "25px", marginRight: "30px" }}
              />
            </Link> */}
            {/* CARD DIV */}
            <div className="accountInfoCard">
              {/* Blue card header */}
              <div className="accountBlueCard">
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "700",
                    marginTop: "35px",
                    fontFamily: "museo-sans",
                  }}
                >
                  Total Balance Due
                </p>
                <p
                  style={{
                    marginTop: "-20px",
                    color: "white",
                    textAlign: "center",
                    fontSize:
                      this.state.outstandingBalance > 100000 ? "40px" : "60px",
                    fontWeight: "700",
                    fontFamily: "museo-sans",
                    overflowWrap: "anywhere",
                  }}
                >
                  ${this?.state?.outstandingBalance?.toFixed(2)}
                </p>
                <div style={{ margin: "-15px 0px -5px 0px" }}>
                  {this.state.outstandingBalance !== 0 && (
                    <Link
                      to={"/Payments"}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        width: "100%",
                      }}
                      tabIndex={21}
                      aria-label="Navigate to add payment method screen"
                    >
                      <AppButton
                        text="PAY NOW"
                        tabIndex={20}
                        arialabel="Pay now button"
                      />
                    </Link>
                  )}
                </div>

                <Link
                  to={"/AddNewPaymentMethod"}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  tabIndex={21}
                  aria-label="Navigate to add payment method screen"
                >
                  <p
                    style={{
                      // marginRight: "65px",
                      // textAlign: "right",
                      color: "white",
                      fontSize: "12px",
                      fontWeight: "700",
                      textDecoration: "underline",
                      fontFamily: "museo-sans",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={writeWhiteIcon}
                      alt="Add payment method icon"
                      style={{ height: "25px", marginRight: "10px" }}
                    />
                    Add Payment Method
                  </p>
                </Link>
              </div>
              {/* LISTED INFO CARD */}
              <div className="listedAccountInfoDiv">
                <div className="accountCardInfoDiv">
                  <div style={{ height: "55%" }}>
                    <p className="accountInfoHeader accountName">
                      Account Name
                    </p>
                    <p className="accountInfoText">
                      {this?.state?.accountData?.Name
                        ? this?.state?.accountData?.Name
                        : "-"}
                    </p>
                  </div>

                  <div style={{ height: "45%" }}>
                    <p className="accountInfoHeader">Customer Number</p>
                    <p className="accountInfoText">
                      {this?.state?.accountData?.Customer_Number__c
                        ? this?.state?.accountData?.Customer_Number__c
                        : "-"}
                    </p>
                  </div>
                </div>
                <span className="accountInfoSeparator" />
                <div className="accountCardInfoDiv">
                  <div style={{ height: "50%" }}>
                    <p className="accountInfoHeader">Service Address</p>
                    <p className="accountInfoText">
                      {this?.state?.accountData?.ShippingAddress?.street
                        ? this?.state?.accountData?.ShippingAddress?.street
                        : "-"}
                    </p>
                    <p className="accountInfoText">
                      {this?.state?.accountData?.ShippingAddress
                        ? `${this?.state?.accountData?.ShippingAddress?.city}, ${this?.state?.contactData[0]?.MailingAddress?.stateCode} ${this?.state?.contactData[0]?.MailingAddress?.postalCode}`
                        : "-"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                    className="blackUnderlineHover"
                  >
                    <p className="accountInfoHeader">Billing Address</p>
                    <Link
                      to={"/EditAccountDetails"}
                      // onClick={() => console.log("Billing Pressed")}
                      style={{
                        background: "transparent",
                        border: "none",
                        padding: "0px",
                      }}
                      tabIndex={22}
                      aria-label="Navigate to edit account details screen"
                    >
                      <img
                        src={writeIcon}
                        alt="Edit account details icon"
                        style={{ height: "25px", marginRight: "10px" }}
                      />
                    </Link>
                  </div>
                  <p className="accountInfoText">
                    {this?.state?.accountData?.BillingAddress?.street
                      ? this?.state?.accountData?.BillingAddress?.street
                      : "-"}
                  </p>
                  <p className="accountInfoText">
                    {this?.state?.accountData?.BillingAddress
                      ? `${this?.state?.accountData?.BillingAddress?.city}, ${this?.state?.contactData[0]?.MailingAddress?.stateCode} ${this?.state?.contactData[0]?.MailingAddress?.postalCode}`
                      : "-"}
                  </p>
                </div>
                <span className="accountInfoSeparator" />
                <div className="accountCardInfoDiv">
                  <div style={{ height: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                      className="blackUnderlineHover"
                    >
                      <p
                        className="accountInfoHeader"
                        style={{ marginBottom: "0px" }}
                      >
                        Phone
                      </p>
                      <Link
                        to={"/EditAccountDetails"}
                        style={{
                          background: "transparent",
                          border: "none",
                          padding: "0px",
                        }}
                        tabIndex={23}
                        aria-label="Navigate to edit account details screen"
                      >
                        <img
                          src={writeIcon}
                          alt="Edit account details icon"
                          style={{ height: "25px", marginRight: "10px" }}
                        />
                      </Link>
                    </div>
                    <p className="accountInfoText">
                      {this?.state?.contactData[0]?.Phone
                        ? this?.state?.contactData[0]?.Phone
                        : "-"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                    className="blackUnderlineHover"
                  >
                    <p className="accountInfoHeader">Email</p>
                    {/* <Link
                      to={"/EditAccountDetails"}
                      style={{
                        background: "transparent",
                        border: "none",
                        padding: "0px",
                      }}
                      tabIndex={24}
                      aria-label="Navigate to edit account details screen"
                    >
                      <img
                        src={writeIcon}
                        alt="Edit account details icon"
                        style={{ height: "25px", marginRight: "10px" }}
                      />
                    </Link> */}
                  </div>
                  <p className="accountInfoText">
                    {this?.state?.contactData[0]?.Email
                      ? this?.state?.contactData[0]?.Email
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {/* Bottom Container */}
            <div className="bottomContainer">
              <div className="bottomLeftContainer">
                <div
                  style={{
                    marginTop: "60px",
                    boxShadow:
                      this.state.scrollToOnLoad === "AutoPay"
                        ? "inset 0px 0px 3px #0000004D, 1px 1px 6px #D54100"
                        : "none",
                  }}
                  id="AutoPayDiv"
                >
                  <p
                    style={{
                      color: "#727272",
                      fontWeight: "700",
                      fontSize: "17px",
                      textAlign: "left",
                      marginLeft: "20px",
                      letterSpacing: "1px",
                    }}
                  >
                    ADD/CHANGE PAYMENT METHOD
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "300",
                      margin: "20px",
                    }}
                  >
                    Click on a card below to set it as your preferred payment
                    method
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.renderCards()}
                    {!this?.state?.paymentMethods && (
                      <div>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "300",
                            margin: "20px",
                          }}
                        >
                          You do not currently have any payment methods
                        </p>
                      </div>
                    )}
                    <>
                      <div className="redUnderlineHover">
                        <Link
                          to={"/AddNewPaymentMethod"}
                          className="addPaymentDiv"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#D54100",
                            marginTop: "10px",
                            background: "transparent",
                            border: "none",
                            textDecoration: "none",
                          }}
                          tabIndex={28}
                          aria-label="Navigate to add payment method screen"
                        >
                          <RxPlusCircled
                            size={35}
                            style={{ marginRight: "10px", borderRadius: 18 }}
                            className="addPaymentIcon"
                            color="#D54100"
                          />

                          <p
                            style={{
                              fontFamily: "museo-sans",
                              fontWeight: "700",
                              display: "contents",
                              fontSize: "16px",
                              textDecoration: "none",
                            }}
                          >
                            Add a payment method
                          </p>
                        </Link>
                      </div>
                    </>
                  </div>
                  {!this?.state?.paymentMethods && (
                    <div>
                      <p
                        style={{
                          marginLeft: "20px",
                          fontSize: "16px",
                          fontWeight: "300",
                          margin: "20px",
                        }}
                      >
                        You do not currently have any payment methods
                      </p>
                    </div>
                  )}
                </div>
                <span
                  className="d-block d-lg-none"
                  style={{
                    display: "block",
                    borderTop: "1px solid #E9E9E9",
                    margin: "20px",
                  }}
                />
              </div>
              <span
                className="d-none d-lg-block"
                style={{
                  display: "block",
                  border: "1px solid #E9E9E9",
                  marginTop: "75px",
                  marginBottom: "75px",
                }}
              />
              <div className="bottomRightContainer">
                <div
                  style={{
                    marginTop: "60px",
                    boxShadow:
                      this.state.scrollToOnLoad === "Notifications" ||
                      this.state.scrollToOnLoad === "AddSMS"
                        ? "inset 0px 0px 3px #0000004D, 1px 1px 6px #D54100"
                        : "none",
                    paddingBottom: "10px",
                  }}
                  id="NotificationPrefDiv"
                >
                  <p
                    style={{
                      color: "#727272",
                      fontWeight: "700",
                      fontSize: "17px",
                      textAlign: "left",
                      marginLeft: "20px",
                      letterSpacing: "1px",
                    }}
                  >
                    SMS notifications
                  </p>
                  {!this.state.showChangeSMSResponse ? (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "300",
                        margin: "20px",
                      }}
                    >
                      By checking this box, I consent to receive text messages
                      to the mobile number provided above regarding my inquiry
                      or monthly service, from Amenity Pool Services Alerts.
                      Message frequency varies, text HELP to 59189 for help,
                      text STOP to end at any time. Msg & data rates may apply.
                      I understand that I am not required to opt in as a
                      condition of purchasing any property, goods, or services.
                      By leaving this box unchecked you will not be opted in for
                      SMS messages at this time. Click the links in our footer
                      to read our Terms & Conditions and Privacy Policy
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        margin: "20px",
                        color: !this.state.smsChangeResponse && "#D54100",
                      }}
                    >
                      {this.state.smsChangeResponse
                        ? "Your SMS update was successful"
                        : "Error updating your SMS settings, please try again."}
                    </p>
                  )}
                  {this.state.showChangeSMSResponse ? (
                    <>
                      {this.state.smsChangeResponse ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        >
                          <RxCheckCircled
                            size={55}
                            color="#1A4EA2"
                            style={{ marginRight: "10px" }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        >
                          <RxCrossCircled
                            size={55}
                            color="#D54100"
                            style={{ marginRight: "10px" }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {this.state.showNotificationsSpinner ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                            marginBottom: "50px",
                          }}
                        >
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <>
                          {/* CHECKBOX DIV */}
                          <div style={{ marginLeft: "20px" }}>
                            <Button
                              onClick={() =>
                                // this.handlePreferredContactMethodUpdate(
                                //   "Text Message"
                                // )
                                this.handleSMSOptIn()
                              }
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                backgroundColor: "transparent",
                                border: "none",
                                color: "black",
                                padding: "0px",
                              }}
                              id="NotificationPrefTabToSMS"
                              tabIndex={30}
                              aria-label="Set SMS as a method of contact"
                            >
                              {this.state.smsOptIn ? (
                                <RiCheckboxLine
                                  size={35}
                                  style={{ mairiginRight: "10px" }}
                                />
                              ) : (
                                <RiCheckboxBlankLine
                                  size={35}
                                  style={{ mairiginRight: "10px" }}
                                />
                              )}
                              <p
                                className="blackUnderlineHover"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  paddingTop: "5px",
                                  fontFamily: "museo-sans",
                                  marginLeft: "15px",
                                }}
                              >
                                You may contact me via text
                              </p>
                            </Button>
                            {this.state.smsOptIn && (
                              <>
                                <Form.Group
                                  className="preferredMethodCellPhoneNum"
                                  style={{
                                    marginTop: "25px",
                                  }}
                                >
                                  <FloatingLabel
                                    controlId="floatingCellPhoneInput"
                                    label="Cell Phone"
                                    className="mb-3 editAccountFormLong"
                                  >
                                    <Form.Control
                                      tabIndex={31}
                                      placeholder="Cell Phone"
                                      value={this.state.cellPhone}
                                      onChange={(event) => {
                                        const newText = event.target.value;

                                        // Check if the text includes only numbers
                                        if (/^\d+$/.test(newText)) {
                                          this.setState({
                                            cellPhone: newText,
                                          });
                                        }
                                      }}
                                      style={{
                                        width: "100%",
                                        height: "60px",
                                        border: "1px solid #D5D5D5",
                                        borderRadius: "5px",
                                      }}
                                      aria-label="Update SMS contact method"
                                    ></Form.Control>
                                  </FloatingLabel>
                                </Form.Group>
                                <div className="preferredMethodCellPhoneNum">
                                  {this.state?.cellPhone?.length === 10 && (
                                    <AppButton
                                      tabindex={32}
                                      text="SUBMIT"
                                      onClick={() => {
                                        this.submitSMSChange();
                                      }}
                                      arialabel="Submit SMS contact number update"
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PrivacyPolicy color="black" tabindex={34} />
              <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  marginRight: "10px",
                  fontSize: "13px",
                }}
              >
                |
              </p>
              <TermsAndPrivacy color="black" tabindex={35} />
            </div>
          </div>
          <Modal
            show={this.state.showContactMethodFailModal}
            onHide={this.handleCloseContactMethodModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="custom-modal-dashboard"
            style={{ borderRadius: "25px" }}
          >
            <Modal.Body>
              <>
                <button
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() =>
                    this.setState({ showContactMethodFailModal: false })
                  }
                  aria-label="Close modal"
                >
                  <IoIosCloseCircleOutline
                    size={50}
                    style={{ marginBottom: "25px" }}
                    color="#d44203"
                  />
                </button>
                <p
                  style={{
                    fontSize: "25px",
                    fontFamily: "Oswald",
                    color: "#d44203",
                    fontWeight: "300",
                    lineHeight: "30px",
                    marginBottom: "50px",
                  }}
                >
                  You must have at least one method selected.
                </p>
              </>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showPaymentUpdateModal}
            onHide={this.handleClosePaymentUpdateModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName="custom-modal-dashboard"
            style={{ borderRadius: "25px" }}
          >
            <Modal.Body>
              <>
                {!this.state.showPaymentUpdateSuccess && (
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() =>
                      this.setState({ showPaymentUpdateModal: false })
                    }
                    aria-label="Close payment update response modal"
                  >
                    <IoIosCloseCircleOutline
                      size={50}
                      style={{ marginBottom: "25px" }}
                      color="#d44203"
                    />
                  </button>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {this.state.paymentUpdateModalType === "Preferred" &&
                  !this.state.showPaymentUpdateSpinner &&
                  !this.state.showPaymentUpdateSuccess ? (
                    <>
                      <p
                        style={{
                          fontSize: "25px",
                          fontFamily: "Oswald",
                          color: "#d44203",
                          fontWeight: "300",
                          lineHeight: "30px",
                          marginBottom: "50px",
                          textAlign: "center",
                        }}
                      >
                        Set card ending in{" "}
                        <span
                          style={{
                            fontSize: "25px",
                            fontFamily: "Oswald",
                            color: "#d44203",
                            fontWeight: "500",
                            lineHeight: "30px",
                            marginBottom: "50px",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.selectedPaymentMethod
                              ?.pymt__Last_4_Digits__c
                          }
                        </span>{" "}
                        as your preferred payment method?
                      </p>
                      {/* <p
                        style={{
                          fontSize: "25px",
                          fontFamily: "Oswald",
                          color: "#d44203",
                          fontWeight: "300",
                          lineHeight: "30px",
                          marginBottom: "50px",
                          textAlign: "center",
                        }}
                      >
                        AUTO PAY WILL BE TURNED ON AUTOMATICALLY FOR THIS
                        PAYMENT METHOD
                      </p> */}
                      <AppButton
                        text="CONFIRM"
                        onClick={() => this.submitPreferredMethodChange()}
                        arialabel="Confirm update preferred payment method"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.paymentUpdateModalType === "Auto Pay" &&
                  !this.state.showPaymentUpdateSpinner &&
                  !this.state.showPaymentUpdateSuccess ? (
                    <>
                      <p
                        style={{
                          fontSize: "25px",
                          fontFamily: "Oswald",
                          color: "#d44203",
                          fontWeight: "300",
                          lineHeight: "30px",
                          marginBottom: "50px",
                          textAlign: "center",
                        }}
                      >
                        Turn{" "}
                        {this.state.preferredPaymentMethod?.Auto_Pay__c
                          ? "off"
                          : "on"}{" "}
                        auto pay for card ending in{" "}
                        <span
                          style={{
                            fontSize: "25px",
                            fontFamily: "Oswald",
                            color: "#d44203",
                            fontWeight: "500",
                            lineHeight: "30px",
                            marginBottom: "50px",
                            textAlign: "center",
                          }}
                        >
                          {
                            this.state.preferredPaymentMethod
                              ?.pymt__Last_4_Digits__c
                          }
                        </span>
                        {"?"}
                      </p>
                      <AppButton
                        text="CONFIRM"
                        onClick={() => this.handleAutopayChange()}
                        arialabel="Confirm auto pay update"
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.showPaymentUpdateSpinner ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ marginBottom: "50px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    ""
                  )}
                  {this.state.showPaymentUpdateError ? (
                    <p
                      style={{
                        fontSize: "25px",
                        fontFamily: "Oswald",
                        color: "#d44203",
                        fontWeight: "300",
                        lineHeight: "30px",
                        marginBottom: "50px",
                        textAlign: "center",
                      }}
                    >
                      Error updating payment method, please try again.
                    </p>
                  ) : (
                    ""
                  )}
                  {this.state.showPaymentUpdateSuccess ? (
                    <>
                      <p
                        style={{
                          fontSize: "25px",
                          fontFamily: "Oswald",
                          color: "#d44203",
                          fontWeight: "300",
                          lineHeight: "30px",
                          marginTop: "50px",
                          marginBottom: "50px",
                          textAlign: "center",
                        }}
                      >
                        Success!
                      </p>
                      <AppButton
                        text="CONTINUE"
                        onClick={() => {
                          window.location.href = "/AccountDetails/AutoPay";
                        }}
                        arialabel="Close success modal"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </Modal.Body>
          </Modal>
        </div>
        {this.state.showLoadErrorModal && <LoadErrorModal />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(AccountDetails);
